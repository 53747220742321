import axios from '@/api/axios'

import { getStorage, ref as sRef, uploadBytes } from 'firebase/storage'

import {
	getDatabase,
	ref,
	onValue,
	get,
	set,
	update,
	remove,
} from '@firebase/database'

const storage = getStorage()
const db = getDatabase()

const LICENSE_STORAGE =
	process.env.NODE_ENV === 'production' ? 'license_test' : 'dev_license_test'

const marketLicenseService = {
	getMeLicenses() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses/me/detail',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLicenses() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getTestRounds() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses/test-rounds',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getTestRoundUserTakeDetail() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-license-test-round-user-take/detail',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getTestRoundWorkBooks(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-test-rounds/${id}/workbooks`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getWorkBook(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-workbooks/${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getWorkBookDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-workbooks/${id}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getTestRoundUserTakeMe() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/market-licenses/test-rounds/me',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	applyTakeAnExam(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-license-test-rounds/${id}/actions/apply-take-an-exam`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	cancelTakeAnExam(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'DELETE',
					url: `/market-license-test-rounds/${id}/actions/apply-take-an-exam`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getWorkbookUserTest(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-license-workbooks/${id}/user-tests/me`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postWorkbookUserTest(id, _answerSheet) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-license-workbooks/${id}/user-tests`,
					data: {
						answerSheet: _answerSheet,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLicenseExamSchedule(courseId) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${courseId}/schedules`,
				)
				onValue(
					licenseExamRef,
					snapshot => {
						if (snapshot.exists()) {
							const data = snapshot.val()
							resolve(data)
						}
					},
					{
						onlyOnce: true,
					},
				)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLicenseExamUserSchedule({ courseId, firebaseUid }) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${courseId}/users_schedule/${firebaseUid}`,
				)
				onValue(
					licenseExamRef,
					snapshot => {
						let data = null
						if (snapshot.exists()) {
							data = snapshot.val()
						}
						resolve(data)
					},
					{
						onlyOnce: true,
					},
				)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postLicenseExamUserSchedule(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_schedule/${payload.firebaseUid}`,
				)

				delete payload.courseId
				delete payload.firebaseUid

				get(licenseExamRef).then(snapshot => {
					if (!snapshot.exists()) {
						set(licenseExamRef, payload)
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateLicenseExamUserSchedule(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_schedule/${payload.firebaseUid}`,
				)

				delete payload.courseId
				delete payload.firebaseUid

				get(licenseExamRef).then(snapshot => {
					if (snapshot.exists()) {
						update(licenseExamRef, payload)
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deleteLicenseExamUserSchedule(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_schedule/${payload.firebaseUid}`,
				)
				remove(licenseExamRef)

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLicenseExamUserWorkbook(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_workbook/${payload.firebaseUid}`,
				)
				onValue(
					licenseExamRef,
					snapshot => {
						if (snapshot.exists()) {
							const data = snapshot.val()
							resolve(data)
						} else {
							resolve(null)
						}
					},
					{
						onlyOnce: true,
					},
				)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postLicenseExamUserWorkbook(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_workbook/${payload.firebaseUid}`,
				)

				delete payload.courseId
				delete payload.firebaseUid

				get(licenseExamRef).then(snapshot => {
					if (!snapshot.exists()) {
						set(licenseExamRef, payload)
					} else {
						const data = snapshot.val()
						if (data.finished) {
							payload.finished = true
						}

						payload.mockExam = true
						update(licenseExamRef, payload)
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateLicenseExamUserWorkbook(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_workbook/${payload.firebaseUid}`,
				)

				delete payload.courseId
				delete payload.firebaseUid

				get(licenseExamRef).then(snapshot => {
					if (snapshot.exists()) {
						update(licenseExamRef, payload)
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	updateLicenseExamUserScheduleAnswer(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const licenseExamRef = ref(
					db,
					`${LICENSE_STORAGE}/${payload.courseId}/users_schedule/${payload.firebaseUid}`,
				)

				get(licenseExamRef).then(snapshot => {
					if (snapshot.exists()) {
						update(licenseExamRef, {
							answer: payload.answerSheet,
						})
					}
				})

				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	uploadCapturedSnapShot(uint8Array, payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const capturesRef = sRef(
					storage,
					`captures/${payload.firebaseUid}/${payload.workbookId}/${payload.fileName}`,
				)
				uploadBytes(capturesRef, uint8Array).then(snapshot => {
					resolve(snapshot)
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	uploadVoiceRecord(uint8Array, payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const recordsRef = sRef(
					storage,
					`voicerecords/${payload.firebaseUid}/${payload.workbookId}/${payload.fileName}`,
				)
				uploadBytes(recordsRef, uint8Array).then(snapshot => {
					resolve(snapshot)
				})
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default marketLicenseService
